//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import eventBus from '@/plugins/eventBus';
import moment from 'moment';
import * as _ from 'underscore';

export default {
  name: 'performanceTable',
  data() {
    return {
      showTable: true,
      showInnerLoading: false,
      isCall: false,
      data: [],
      columns: [],
      pagination: {

      },
    };
  },
  computed: {
    ...mapGetters({
      getFilterDate: 'getFilterDate',
      currentAIAccount: 'getCurrentAIAccount',
      currentUser: 'getCurrentUser',
      darkTheme: 'getDarkTheme',
    }),
  },
  methods: {
    ...mapActions({
      requestIntentStatsAll: 'requestIntentStatsAll',
    }),
    onDateUpdate(date) {
      const that = this;
      if (that.isValid(date.start) && that.isValid(date.end)) {
        that.isCall = false;
        const data = {
          aiAccount: that.currentAIAccount.id,
          startdate: date.start,
          enddate: date.end,
        };
        that.updatePerformancetable(data);
      }
    },
    updatePerformancetable(data) {
      const that = this;
      that.showTable = true;
      that.showInnerLoading = true;
      const days = moment(this.getFilterDate.end, 'DD-MM-YYYY').diff(moment(this.getFilterDate.start, 'DD-MM-YYYY'), 'days') + 1;
      if (days > 0 && days < 10) {
        data.slotMins = 1440;
      } else {
        data.slotMins = 10080;
      }
      this.requestIntentStatsAll(data).then((res) => {
        if (res.headers.length > 0) {
          that.showTable = true;
          setTimeout(() => {
            that.updateHeaders(res.headers);
            that.pagination.rowsPerPage = res.result.length;
            that.data = res.result;
          });
        } else {
          setTimeout(() => {
            that.showInnerLoading = false;
          }, 100);
        }
      }).catch((err) => {
        setTimeout(() => {
          that.showInnerLoading = false;
          that.showTable = false;
          that.isCall = false;
        }, 1000);
      }).finally(() => {
        setTimeout(() => {
          that.showInnerLoading = false;
          that.isCall = false;
        }, 1000);
      });
    },
    updateHeaders(data) {
      const that = this;
      const columns = [];
      _.forEach(data, (head) => {
        let align = 'center';
        if (head.label == 'intent') {
          align = 'left';
        }
        columns.push({
          name: head.label,
          align,
          label: head.value,
          field: head.label,
          sortable: true,
        });
      });
      that.columns = columns;
    },
    onAccountUpdate(account) {
      const that = this;
      const data = {
        aiAccount: account.id,
        startdate: that.getFilterDate.start,
        enddate: that.getFilterDate.end,
      };
      that.isCall = false;
      that.updatePerformancetable(data);
    },
    isValid(value) {
      if (value === undefined || value === null || (typeof value === 'object' && Object.keys(value).length === 0) || (typeof value === 'string' && value.trim().length === 0)) {
        return false;
      }
      return true;
    },
  },
  mounted() {
    const that = this;
    const data = {
      aiAccount: that.currentAIAccount.id,
      startdate: that.getFilterDate.start,
      enddate: that.getFilterDate.end,
    };
    that.updatePerformancetable(data);
  },
  created() {
    eventBus.$on('updateDate', this.onDateUpdate);
    eventBus.$on('updateAIAccount', this.onAccountUpdate);
  },
  beforeDestroy() {
    eventBus.$off('updateDate', this.onDateUpdate);
    eventBus.$off('updateAIAccount', this.onAccountUpdate);
  },
  destroyed() {
    eventBus.$off('updateDate', this.onDateUpdate);
    eventBus.$off('updateAIAccount', this.onAccountUpdate);
  },
};
